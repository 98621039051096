import React from 'react';
import { Box, Stack, Typography, styled, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import FormContacto from './FormContacto';
import CardContactoComponent from './CardContactoComponent';
import { MODULE_CONST } from '../navbar';
import { numberPhone } from '../LandingView';

export const instagramHref = 'https://www.instagram.com/marycarylopez_94/';

const ContactoView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: COLORS.VERDE_OSCURO,
    flexDirection: 'column',
    position: 'relative',
  }));

  const titleBotton = (
    <Stack direction="column" alignItems="center" justifyContent={'center'}>
      <Typography
        sx={{
          fontFamily: 'TTNormsBold',
          color: COLORS.BLANCO,
          fontSize: isMobile ? '24pt' : '3.5vw',
          lineHeight: isMobile ? '10vw' : '3vw',
          mb: isMobile ? '30px' : '4vw',
          mt: '5vw',
        }}
      >
        {t('contacto.title')}
      </Typography>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent={'flex-start'}
      >
        <CardContactoComponent
          data={{
            img: IMG.phoneSvg,
            title: t('contacto.form.phone'),
            value: '786-646-7818',
            mr: isMobile ? '0' : '4vw',
            href: `tel:${numberPhone}`,
          }}
        />
        <CardContactoComponent
          data={{
            img: IMG.emailSvg,
            title: t('contacto.form.email'),
            value: 'maria.lopez.advisor@icloud.com',
            mr: isMobile ? '0' : '4vw',
            href: 'mailto:maria.lopez.advisor@icloud.com',
          }}
        />
        <CardContactoComponent
          data={{
            img: IMG.emailSvg,
            title: 'Instagram',
            value: '@marycarylopez_94',
            href: instagramHref,
          }}
        />
      </Stack>
    </Stack>
  );

  const footer = (
    <>
      <img
        src={IMG.logoSvg}
        alt="Wasa"
        color={COLORS.BEIGE}
        style={{ width: isMobile ? '120px' : '6vw' }}
      />
      <Typography
        textAlign="center"
        sx={{
          fontFamily: 'TTNormsRegular',
          color: COLORS.BLANCO,
          fontSize: isMobile ? '14pt' : '1vw',
          lineHeight: isMobile ? '10vw' : '3vw',
        }}
      >
        ML & Advisors
      </Typography>
      <Typography
        textAlign="center"
        sx={{
          fontFamily: 'TTNormsRegular',
          color: COLORS.BLANCO,
          fontSize: isMobile ? '12pt' : '1vw',
          lineHeight: isMobile ? '10vw' : '2vw',
          marginTop: isMobile ? '20px' : null,
        }}
      >
        ML & Advisors © 2020-2024 All rights reserved.
      </Typography>
    </>
  );

  return (
    <CustomBox id={MODULE_CONST.contacto} component="section">
      <Box
        sx={{
          backgroundImage: `url(${IMG.footorPng})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: '100%',
          minHeight: isMobile ? null : '110vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isMobile ? 'center' : 'flex-end',
          color: 'white',
          py: isMobile ? '40px' : null,
        }}
      >
        {isMobile && titleBotton}
      </Box>
      <FormContacto footer={!isMobile && footer}>
        {!isMobile && titleBotton}
      </FormContacto>
      <Box
        sx={{
          width: '100vw',
          height: isMobile ? '40vh' : '95vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isMobile ? 'center' : 'flex-end',
          color: 'white',
          flexDirection: 'column',
          backgroundColor: COLORS.AZUL_MARINO,
        }}
      >
        {isMobile && footer}
      </Box>
    </CustomBox>
  );
};

export default ContactoView;
