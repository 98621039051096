import React from 'react';
import { Box, styled, Typography, Stack, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const SomosView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: isMobile ? 'auto' : '100vh',
    backgroundColor: COLORS.BEIGE,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  }));

  return (
    <CustomBox component="section">
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: '15vw',
            px: isMobile ? '30px' : '20vw',
          }}
        >
          <img
            src={IMG.logoTextSvg}
            alt="Wasa"
            color={COLORS.BEIGE}
            style={{
              width: isMobile ? '120px' : '12vw',
              paddingBottom: isMobile ? '40px' : '3vw',
            }}
          />
          <Typography
            sx={{
              fontFamily: 'TTNormsBold',
              color: COLORS.VERDE_OSCURO,
              fontSize: isMobile ? '30pt' : '3vw',
              lineHeight: isMobile ? '20px' : '3vw',
              mb: isMobile ? '50px' : '2vw',
            }}
          >
            {t('somos.title')}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'TTNormsRegular',
              color: COLORS.NEGRO,
              fontSize: isMobile ? '14pt' : '1.2vw',
              lineHeight: isMobile ? '25px' : '2vw',
              textAlign: 'start',
            }}
          >
            {t('somos.text')}
            <br />
            <br />
            {t('somos.text1')}
            <br />
            <br />
            {t('somos.text2')}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'TTNormsBold',
              color: COLORS.NEGRO,
              fontSize: isMobile ? '18pt' : '1.5vw',
              lineHeight: isMobile ? '25px' : '2vw',
              mt: isMobile ? '50px' : '2vw',
            }}
          >
            {t('somos.listo')}
          </Typography>
        </Box>
      </Stack>
    </CustomBox>
  );
};

export default SomosView;
