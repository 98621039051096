import axios from 'axios';
import { baseURL } from '../../config/apis';

export const LANDING_CONST = 'landing';
export const EMAIL_CONST = 'email';

export const sendFormService = async (data) => {
  const url = `${baseURL}/${EMAIL_CONST}/send`;
  var res = await axios.post(url, data);
  return res;
};
