const generateRandomNumber = (min = 100000000000000, max = 999999999999999) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const COLORS = {
  VERDE_CLARO: '#9BBF63',
  VERDE_OSCURO: '#478661',
  BLANCO: '#FFFFFF',
  BEIGE: '#EBEBEB',
  NEGRO: '#000000',
  AZUL_MARINO: '#263238',
};

export { generateRandomNumber, COLORS };
