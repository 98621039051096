import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import * as MODULE from '../modules';

const Routers = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export const routesPages = [
  '/contactos', // 0
];

function AppRoutes() {
  let location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route path="/" element={<MODULE.Landing />} />
          {routesPages.map((el, i) => (
            <Route key={i} path={el} element={<h1>Routes</h1>} />
          ))}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default Routers;
