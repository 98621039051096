import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  TextField,
  Typography,
  keyframes,
  useMediaQuery,
} from '@mui/material';
import { COLORS } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

const slide = keyframes`
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const dataArray = [
  { value: 1, text: 'Health insurance' },
  { value: 2, text: 'Life insurance' },
  { value: 3, text: 'Supplement insurance' },
  { value: 4, text: 'Vision & dental' },
];

const SelectProductoComponent = ({ isError, product, onChange = () => '' }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isList, setIsList] = useState(false);
  const [select, setSelect] = useState(null);

  const optionsData = [
    { value: 1, text: t('productos.salud') },
    { value: 2, text: t('productos.vida') },
    { value: 3, text: t('productos.comple') },
    { value: 4, text: t('productos.vision') },
  ];

  useEffect(() => {
    if (product) {
      const sd = dataArray.find((el) => el.text === product);
      setSelect(sd?.value);
    } else {
      setSelect(null);
    }
  }, [product]);

  function options({ value, radius = true, text }) {
    const igual = select === value;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          textAlign="center"
          onClick={() => {
            onChange(value);
            setIsList(false);
          }}
          sx={{
            width: isMobile ? '70%' : '60%',
            fontFamily: igual ? 'TTNormsBold' : 'TTNormsRegular',
            color: igual ? COLORS.VERDE_OSCURO : COLORS.NEGRO,
            fontSize: isMobile ? '16pt' : '1.5vw',
            py: isMobile ? '15px' : '.7vw',
            borderBottom:
              radius &&
              `${igual ? '3px' : '1px'} solid ${
                igual ? COLORS.VERDE_OSCURO : COLORS.NEGRO
              }`,
            cursor: 'pointer',
            '&:hover': {
              borderBottom: radius && `3px solid ${COLORS.VERDE_OSCURO}`,
              color: COLORS.VERDE_OSCURO,
            },
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          size="small"
          value={optionsData.find((el) => el.value === select)?.text || ''}
          onClick={() => setIsList(!isList)}
          id={'product'}
          InputProps={{
            style: {
              borderRadius: 0,
              border: `1px solid grey`,
              paddingLeft: isMobile ? '25px' : '2vw',
            },
            readOnly: true,
          }}
          error={isError}
          helperText={
            isError &&
            `${t('contacto.form.product')} ${t('contacto.form.invalid')}`
          }
        />
        <Box
          sx={{
            position: 'absolute',
            left: 0,
          }}
        >
          {!isList ? (
            <KeyboardArrowDownIcon
              sx={{
                fontSize: isMobile ? '40px' : '3vw',
                color: COLORS.VERDE_OSCURO,
                mb: isError ? '1.2vw' : 0,
              }}
            />
          ) : (
            <KeyboardArrowUpIcon
              sx={{
                fontSize: isMobile ? '40px' : '3vw',
                color: COLORS.VERDE_OSCURO,
                mb: isError ? '1.2vw' : 0,
              }}
            />
          )}
        </Box>
      </Box>
      {isList && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: COLORS.BEIGE,
            py: '1vw',
            animation: `${slide} 0.5s ease`,
          }}
        >
          {optionsData.map((option, index) =>
            options({
              value: option.value,
              text: option.text,
              radius: index !== 3,
            })
          )}
        </Box>
      )}
    </>
  );
};

export default SelectProductoComponent;
