import React from 'react';
import {
  Box,
  Button,
  styled,
  Typography,
  Stack,
  useMediaQuery,
} from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { wasaDesktop, wasaMobile } from '../LandingView';

export const onClickWasa = (isMobile) => {
  window.open(
    isMobile ? wasaMobile : wasaDesktop,
    '_blank' // Abre el enlace en una nueva pestaña
  );
};

const FamiliaView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.AZUL_MARINO,
  }));

  const StyledButton = styled(Button)({
    height: isMobile ? '50px' : '3vw',
    // width: isMobile ? '80vw' : '16vw',
    borderRadius: 0,
    backgroundColor: COLORS.VERDE_OSCURO,
    color: COLORS.BLANCO,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.VERDE_OSCURO,
    },
    paddingLeft: isMobile ? '5vw' : '2vw',
    paddingRight: isMobile ? '5vw' : '2vw',
    fontFamily: 'TTNormsBold',
    fontSize: isMobile ? '7vw' : '1.5vw',
    marginTop: isMobile ? '40px' : '2vw',
    marginBottom: isMobile ? '60px' : 0,
  });

  const image = (
    <img
      src={isMobile ? IMG.familiaPng : IMG.familia2Png}
      alt="Wasa"
      color={COLORS.BEIGE}
      style={{
        width: isMobile ? '100%' : '40vw',
        height: isMobile ? '230px' : '100vh',
        objectFit: 'cover',
      }}
    />
  );

  return (
    <CustomBox component="section">
      <Stack
        sx={{
          p: isMobile ? 0 : '10vw',
        }}
        alignItems={isMobile ? 'center' : 'flex-start'}
      >
        {isMobile && image}
        <Typography
          sx={{
            fontFamily: 'TTNormsBold',
            color: COLORS.VERDE_CLARO,
            fontSize: isMobile ? '16pt' : '2.5vw',
            lineHeight: isMobile ? '25px' : '3vw',
            mb: '4vw',
            px: isMobile ? '30px' : 0,
            pt: isMobile ? '40px' : 0,
            textAlign: isMobile ? 'center' : null,
          }}
        >
          {t('familia.title')}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'TTNormsRegular',
            color: COLORS.BLANCO,
            fontSize: isMobile ? '14pt' : '1.2vw',
            lineHeight: isMobile ? '25px' : '2vw',
            px: isMobile ? '30px' : 0,
            pt: isMobile ? '20px' : 0,
            textAlign: 'start',
          }}
        >
          {t('familia.text')}
        </Typography>

        <StyledButton onClick={() => onClickWasa(isMobile)}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
          >
            <img
              src={IMG.wasaSvg}
              alt="Wasa"
              color={COLORS.BEIGE}
              style={{ width: isMobile ? '20px' : '2vw' }}
            />
            <Typography
              sx={{
                fontFamily: 'TTNormsBold',
                color: COLORS.BLANCO,
                fontSize: isMobile ? '12pt' : '1.2vw',
                px: isMobile ? '5px' : 0,
              }}
            >
              {t('familia.button')}
            </Typography>
          </Stack>
        </StyledButton>
      </Stack>
      {!isMobile && <Box sx={{ width: '40vw', height: '100%' }}>{image}</Box>}
    </CustomBox>
  );
};

export default FamiliaView;
