import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import NavbarView from './navbar';
import HeaderView from './header';
import * as IMG from '../../static/images';
import ProductosView from './productos';
import FamiliaView from './familia';
import ObamacareView from './obamacare';
import SomosView from './somos';
import ContactoView from './contacto';

export const numberPhone = '+17866467818';
export const wasaMobile = `whatsapp://send?phone=${numberPhone}text=Necesito%20consultar%20sobre...`;
export const wasaDesktop = `https://web.whatsapp.com/send?phone=${numberPhone}&text=Necesito%20consultar%20sobre...`;

const LandingView = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // para mobile
  // const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // para desktop

  const wasa = (
    <Box
      component={'a'}
      sx={{
        position: 'fixed',
        bottom: isMobile ? '15px' : '2vw',
        right: isMobile ? '15px' : '2vw',
        height: isMobile ? '55px' : '5vw',
        width: isMobile ? '55px' : '5vw',
        backgroundColor: '#25D366',
        borderRadius: '1vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      target="_blank"
      href={isMobile ? wasaMobile : wasaDesktop}
    >
      <img
        src={IMG.wasaSvg}
        alt="Wasa"
        style={{
          height: isMobile ? '35px' : '3vw',
          width: isMobile ? '35px' : '3vw',
        }}
      />
    </Box>
  );

  return (
    <Box position="relative">
      <NavbarView />
      <HeaderView />
      <ProductosView />
      <FamiliaView />
      <ObamacareView />
      <SomosView />
      <ContactoView />
      {wasa}
    </Box>
  );
};

export default LandingView;
