import React from 'react';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';

const CardContactoComponent = ({ data }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ mr: data.mr, mb: isMobile ? 2 : 0 }}
    >
      <Box
        width={isMobile ? '45px' : '3vw'}
        sx={{ pr: isMobile ? '15px' : '1vw' }}
      >
        <img src={data.img} alt={data.title} />
      </Box>
      <Stack direction="column" alignItems="flex-start">
        <Typography
          variant="body1"
          color={data.white ? COLORS.VERDE_OSCURO : COLORS.BEIGE}
          textAlign="center"
          fontFamily="TTNormsBold"
          fontSize={isMobile ? '16pt' : '1vw'}
          lineHeight={isMobile ? '25px' : '1.5vw'}
        >
          {data.title}
        </Typography>
        {data.href ? (
          <a
            href={data.href}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography
              variant="body1"
              color={data.white ? COLORS.VERDE_OSCURO : COLORS.BEIGE}
              textAlign="center"
              fontFamily="TTNormsMedium"
              fontSize={isMobile ? '14pt' : '1vw'}
              sx={{
                '&:hover': {
                  fontSize: '1em',
                  color: COLORS.AZUL_MARINO,
                },
              }}
            >
              {data.value}
            </Typography>
          </a>
        ) : (
          <Typography
            variant="body1"
            color={data.white ? COLORS.VERDE_OSCURO : COLORS.BEIGE}
            textAlign="center"
            fontFamily="TTNormsMedium"
            fontSize={isMobile ? '14pt' : '1vw'}
          >
            {data.value}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default CardContactoComponent;
