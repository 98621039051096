import React from 'react';
import { Box, styled, Stack, useMediaQuery } from '@mui/material';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import FormContactoComponent from './components/FormContactoComponents';

const FormContacto = ({ children, footer }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    width: isMobile ? '100%' : null,
    position: isMobile ? null : 'absolute',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: isMobile ? null : '100vh',
    backgroundColor: 'transparent',
    flexDirection: 'column',
  }));

  return (
    <CustomBox component="section">
      {children}
      <Stack
        direction="column"
        sx={{
          backgroundColor: COLORS.BLANCO,
          p: isMobile ? '30px 0' : '3vw',
          pb: isMobile ? '30px' : null,
          mt: isMobile ? null : '5vw',
          mb: isMobile ? null : '3vw',
          width: isMobile ? '100%' : '34vw',
        }}
      >
        <FormContactoComponent />
      </Stack>
      {footer}
    </CustomBox>
  );
};

export default FormContacto;
