import React from 'react';
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import * as IMG from '../../../static/images';
import { useTranslation } from 'react-i18next';
import { onClickWasa } from '../familia';
import { numberPhone } from '../LandingView';

const CardProductosComponent = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const StyledButton = styled(Button)({
    height: '2.5vw',
    width: '17vw',
    borderRadius: 0,
    backgroundColor: COLORS.VERDE_CLARO,
    color: 'black',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.VERDE_CLARO,
    },
    fontFamily: 'TTNormsRegular',
    fontSize: isMobile ? '7vw' : '1vw',
  });

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={data.white ? COLORS.BEIGE : COLORS.VERDE_OSCURO}
      width="25%"
      height="90vh"
    >
      <Box
        width="8vw"
        height={'7vw'}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={data.img}
          alt={data.title}
          style={{
            width: '8vw !important',
          }}
        />
      </Box>
      <Box height="8vw">
        <Typography
          color={data.white ? COLORS.VERDE_OSCURO : COLORS.BEIGE}
          textAlign="center"
          fontFamily="TTNormsBold"
          fontSize={'2vw'}
          paddingX={data.pd ?? '8vw'}
          lineHeight="2.1vw"
          marginY="2vw"
        >
          {data.title}
        </Typography>
      </Box>

      <StyledButton onClick={() => onClickWasa(isMobile)}>
        {t('productos.more')}
        <img
          src={IMG.arrowSvg}
          alt={'arrow'}
          color={COLORS.NEGRO}
          style={{
            width: '20px',
            marginLeft: '10px',
          }}
        />
      </StyledButton>
    </Stack>
  );
};

export default CardProductosComponent;
