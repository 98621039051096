import React from 'react';
import { Box, Button, styled, Typography, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MODULE_CONST } from '../navbar';
import { Link } from 'react-scroll';

const HeaderView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    backgroundColor: COLORS.AZUL_MARINO,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  }));

  const StyledButton = styled(Button)({
    height: isMobile ? '13vw' : '4vw',
    borderRadius: 0,
    backgroundColor: COLORS.VERDE_OSCURO,
    color: COLORS.BLANCO,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.VERDE_OSCURO,
    },
    paddingLeft: isMobile ? '5vw' : '2vw',
    paddingRight: isMobile ? '5vw' : '2vw',
    fontFamily: 'TTNormsBold',
    fontSize: isMobile ? '7vw' : '1.5vw',
    marginTop: isMobile ? '3vw' : '2vw',
  });

  return (
    <CustomBox id={MODULE_CONST.inicio} component="header">
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${IMG.headerPng})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: '100vw',
          height: isMobile ? '90vh' : '100vh',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <Box
          sx={{
            pb: isMobile ? '30vw' : '8vw',
          }}
          textAlign="center"
        >
          <Typography
            color={COLORS.BLANCO}
            textAlign="center"
            sx={{
              width: isMobile ? '70vw' : '40vw',
              fontSize: isMobile ? '30pt' : '3.5vw',
              fontFamily: 'TTNormsBold',
              lineHeight: isMobile ? '10vw' : '4vw',
            }}
          >
            {t('header.text')}
          </Typography>
          <Link
            to={MODULE_CONST.contacto} // El id del elemento al que quieres desplazarte
            smooth={true}
            duration={500}
          >
            <StyledButton>{t('header.plan')}</StyledButton>
          </Link>
        </Box>
      </Box>
    </CustomBox>
  );
};

export default HeaderView;
