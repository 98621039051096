import React from 'react';
import { Box, styled, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import CardProductosComponent from './CardProductosComponent';
import MobileProductosView from './MobileProductosView';

const ProductosView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'red',
  }));

  return isMobile ? (
    <MobileProductosView />
  ) : (
    <CustomBox component="section">
      <CardProductosComponent
        data={{
          img: IMG.saludWhiteSvg,
          title: t('productos.salud'),
          white: false,
        }}
      />
      <CardProductosComponent
        data={{
          img: IMG.saludSvg,
          title: t('productos.vida'),
          white: true,
        }}
      />
      <CardProductosComponent
        data={{
          img: IMG.saludWhiteSvg,
          title: t('productos.comple'),
          white: false,
          pd: '6vw',
        }}
      />
      <CardProductosComponent
        data={{
          img: IMG.visionSvg,
          title: t('productos.vision'),
          white: true,
          pd: '5.5vw',
        }}
      />
    </CustomBox>
  );
};

export default ProductosView;
