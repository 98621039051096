import { PERSIST, TOKEN } from '../constants';

export const baseURL = 'https://www.mlandadvisors.com:3000/api'; // Server
// export const baseURL = 'http://64.23.150.29:3000/api'; // Server

export const token = ({ isFormData = false, isToken = true }) => {
  const token = localStorage.getItem(TOKEN);
  const headers = {
    'Content-Type': 'application/json',
  };
  if (isToken) headers['Authorization'] = `Bearer ${token}`;
  if (isFormData) headers['Content-Type'] = 'multipart/form-data';
  return headers;
};

export const isTokenValid = (res) => {
  if (res.status === 401) {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(PERSIST);
    setTimeout(() => {
      window.location.replace(`${baseURL}`);
    }, 1000);
  }
};
