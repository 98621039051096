import React, { useState } from 'react';
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { COLORS } from '../../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import SelectProductoComponent, { dataArray } from './SelectProductoComponent';
import { sendFormAction } from '../../LandingAction';
import { useNotification } from '../../../../helpers/Notification';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const phoneRegex = /^[0-9+]*$/; // Asume que el número de teléfono tiene entre 10 y 14 dígitos
const nameRegex = /^[a-zA-Z\s]*$/; // Asume que el nombre solo contiene letras y espacios

const validateEmail = (email) => emailRegex.test(email);
const validatePhone = (phone) => phoneRegex.test(phone);
const validateName = (name) => nameRegex.test(name);

const FormContactoComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { notify } = useNotification();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initData = {
    fullname: '',
    phone: '',
    email: '',
    code: '',
    product: '',
  };

  const [dataForm, setDataForm] = useState(initData);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState({
    fullname: false,
    email: false,
    phone: false,
    code: false,
    product: false,
  });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(isValid).map((key) => {
      const val = dataForm[key];
      const valid = !val || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setIsValid(r);
    return !va.includes(true);
  };

  const validateFields = () => {
    setIsValid({
      fullname: validateName(dataForm.phone) && dataForm.fullname === '',
      email: validateEmail(dataForm.email) && dataForm.email === '',
      phone: validatePhone(dataForm.phone) && dataForm.phone === '',
      code: dataForm.code === '',
      product: dataForm.product === '',
    });
  };

  const _setDataForm = (key, value) =>
    setDataForm({ ...dataForm, [key]: value });

  const StyledButton = styled(Button)({
    height: isMobile ? '50px' : '3vw',
    width: isMobile ? '50vw' : '16vw',
    borderRadius: 0,
    backgroundColor: COLORS.VERDE_OSCURO,
    color: COLORS.BLANCO,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.VERDE_OSCURO,
    },
    paddingLeft: isMobile ? '5vw' : '2vw',
    paddingRight: isMobile ? '5vw' : '2vw',
    fontFamily: 'TTNormsBold',
    fontSize: isMobile ? '7vw' : '1.5vw',
    marginTop: isMobile ? '40px' : '2vw',
    marginBottom: isMobile ? '60px' : 0,
  });

  function titleComponent(value) {
    return (
      <Typography
        textAlign="center"
        sx={{
          fontFamily: 'TTNormsBold',
          color: COLORS.VERDE_OSCURO,
          fontSize: isMobile ? '16pt' : '1.5vw',
          lineHeight: isMobile ? '10vw' : '3vw',
          mt: dataForm === 'Nombre completo' ? 0 : isMobile ? '10px' : '1vw',
        }}
      >
        {value}
      </Typography>
    );
  }

  const styledx = {};

  return (
    <Box component="form" sx={{ px: isMobile ? '30px' : null }}>
      {/* {JSON.stringify(dataForm)} */}
      {titleComponent(t('contacto.form.fullname'))}
      <TextField
        fullWidth
        size="small"
        value={dataForm.fullname}
        onChange={(e) => {
          _setDataForm('fullname', e.target.value);
        }}
        id="fullname"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: 0,
            border: `1px solid grey`,
            ...styledx,
          },
        }}
        error={isValid.fullname}
        helperText={
          isValid.fullname && `${t('contacto.form.fullname')} inválido`
        }
      />
      {titleComponent(t('contacto.form.phone'))}
      <TextField
        fullWidth
        size="small"
        value={dataForm.phone}
        onChange={(e) => {
          _setDataForm('phone', e.target.value);
        }}
        id="phone"
        inputProps={{ maxLength: 15 }}
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: 0,
            border: `1px solid grey`,
          },
        }}
        error={isValid.phone}
        helperText={isValid.phone && `${t('contacto.form.phone')} inválido`}
      />
      {titleComponent(t('contacto.form.email'))}
      <TextField
        fullWidth
        size="small"
        value={dataForm.email}
        onChange={(e) => {
          _setDataForm('email', e.target.value);
        }}
        id={'email'}
        InputProps={{
          style: {
            borderRadius: 0,
            border: `1px solid grey`,
          },
        }}
        error={isValid.email}
        helperText={isValid.email && `${t('contacto.form.email')} inválido`}
      />
      {titleComponent(t('contacto.form.code'))}
      <TextField
        fullWidth
        size="small"
        value={dataForm.code}
        onChange={(e) => _setDataForm('code', e.target.value)}
        id={'code'}
        inputProps={{ maxLength: 10 }}
        InputProps={{
          style: {
            borderRadius: 0,
            border: `1px solid grey`,
          },
        }}
        error={isValid.code}
        helperText={isValid.code && `${t('contacto.form.code')} inválido`}
      />
      {titleComponent(t('contacto.form.product'))}
      <SelectProductoComponent
        isError={isValid.product}
        product={dataForm.product}
        onChange={(value) => {
          const sd = dataArray.find((el) => el.value === value);
          debugger;
          _setDataForm('product', sd.text);
        }}
      />

      <center>
        {loading ? (
          <CircularProgress
            sx={{
              color: COLORS.VERDE_OSCURO,
              mt: isMobile ? '40px' : '2vw',
              mb: isMobile ? '60px' : 0,
            }}
          />
        ) : (
          <StyledButton
            onClick={async () => {
              const vd =
                validateName(dataForm.fullname) &&
                validateEmail(dataForm.email) &&
                validatePhone(dataForm.phone);
              if (_validDataForm() && vd) {
                validateFields();
                setLoading(true);
                const res = await sendFormAction({ notify }, dataForm);
                if (res) {
                  setDataForm(initData);
                }
                setLoading(false);
              }
            }}
          >
            <Typography
              sx={{
                fontFamily: 'TTNormsRegular',
                color: COLORS.BLANCO,
                fontSize: isMobile ? '16pt' : '1.2vw',
                px: isMobile ? '10px' : 0,
              }}
            >
              {t('contacto.form.button')}
            </Typography>
          </StyledButton>
        )}
      </center>
    </Box>
  );
};

export default FormContactoComponent;
