import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  styled,
  Button,
  Stack,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import * as Redux from 'react-redux';
import { COLORS } from '../../../helpers';
import * as IMG from '../../../static/images';
import { useTranslation } from 'react-i18next';
import { I18N_LANG } from '../../../constants';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import MenuMobileComponent from './MenuMobileComponent';
import { keyframes } from '@mui/system';
import { Link } from 'react-scroll';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: '3vw',
});

const zoom = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledButton = styled(Button)({
  height: '2vw',
  width: '8vw',
  borderRadius: '1vw',
  backgroundColor: COLORS.VERDE_OSCURO,
  color: COLORS.BLANCO,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: COLORS.VERDE_OSCURO,
  },
  fontFamily: 'TTNormsRegular',
});

const StyledLink = styled(Button)({
  height: '2vw',
  borderRadius: '1vw',
  color: COLORS.BLANCO,
  '&:hover': {},
  paddingLeft: '1.3vw',
  paddingRight: '1.3vw',
  fontFamily: 'TTNormsBold',
});

export const MODULE_CONST = {
  inicio: 'inicio',
  contacto: 'contacto',
};

const NavbarView = () => {
  const dispatch = Redux.useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const landingStore = Redux.useSelector((state) => state.landing);

  const [lang, setLang] = useState(
    window.localStorage.getItem(I18N_LANG) === 'en' ? 'en' : 'es'
  );

  const changeLang = (lang) => {
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY > 100);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <AppBar
        component="nav"
        position="sticky"
        sx={{
          height: isMobile ? '15vw' : '5vw',
          backgroundColor: COLORS.AZUL_MARINO,
          display: 'flex',
          justifyContent: 'center',
          boxShadow: isScrolled ? `0px 2px 5px  ${COLORS.VERDE_CLARO}` : 'none',
        }}
        elevation={0}
      >
        <StyledToolbar>
          <img
            src={IMG.logoSvg}
            alt="headerImg"
            style={{
              width: isMobile ? '14vw' : '5vw',
            }}
          />
          {isMobile ? (
            <IconButton
              fontSize="inherit"
              aria-label="menu"
              sx={{
                color: COLORS.VERDE_CLARO,
              }}
              onClick={() =>
                dispatch({
                  type: 'IS_MENU_LANDING',
                  payload: !landingStore.isMenu,
                })
              }
            >
              {landingStore.isMenu ? (
                <CloseIcon
                  sx={{
                    fontSize: '45px',
                    animation: `${zoom} 0.5s ease`,
                  }}
                />
              ) : (
                <MenuIcon
                  sx={{
                    fontSize: '45px',
                    animation: `${zoom} 0.5s ease`,
                  }}
                />
              )}
            </IconButton>
          ) : (
            <Stack direction="row" spacing={2}>
              <Link
                to={MODULE_CONST.inicio} // El id del elemento al que quieres desplazarte
                smooth={true}
                duration={500}
              >
                <StyledLink onClick={() => {}}>{t('navbar.inicio')}</StyledLink>
              </Link>
              <Link
                to={MODULE_CONST.contacto} // El id del elemento al que quieres desplazarte
                smooth={true}
                duration={500}
              >
                <StyledLink onClick={() => {}}>
                  {t('navbar.contacto')}
                </StyledLink>
              </Link>
              <StyledButton
                onClick={() => changeLang(lang === 'es' ? 'en' : 'es')}
              >
                {lang === 'es' ? t('langEn') : t('langEs')}
              </StyledButton>
            </Stack>
          )}
        </StyledToolbar>
      </AppBar>
      {isMobile && landingStore.isMenu && (
        <MenuMobileComponent lang={lang} changeLang={changeLang} />
      )}
    </>
  );
};

export default NavbarView;
