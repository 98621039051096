export const YH_SEGUROS = 'yhSeguros';
export const TOKEN = `${YH_SEGUROS}Token`;
export const EMAIL = `${YH_SEGUROS}Email`;
export const I18N = `${YH_SEGUROS}I18n`;
export const I18N_LANG = `${YH_SEGUROS}I18Lng`;
export const REMEMBER_ME = `${YH_SEGUROS}RemenberMe`;
export const PERSIST = `persist:${YH_SEGUROS}`;

export const device = {
  mobile: '(min-width: 280px) and (max-width: 719px)',
  tablet: '(min-width: 720px) and (max-width: 980px)',
  laptop: '(min-width: 981px) and (max-width: 1199px)',
  desktop: '(max-width: 1200px)',
};
