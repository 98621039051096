import { sendFormService } from './LandingService';

export const sendFormAction = async ({ notify }, data) => {
  try {
    var res = await sendFormService(data);
    notify(res.data.message);
    return true;
  } catch (error) {
    notify(error.message ?? error.response.data.message);
    return false;
  }
};
