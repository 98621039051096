import React from 'react';
import {
  Box,
  styled,
  useMediaQuery,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import * as IMG from '../../../static/images';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../helpers';
import { onClickWasa } from '../familia';
import { numberPhone } from '../LandingView';

const MobileProductosView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.VERDE_OSCURO,
    flexDirection: 'column',
    textAlign: 'center',
    padding: '70px 40px',
  }));

  function card(data) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        backgroundColor={COLORS.BEIGE}
        width="100%"
        sx={{
          py: '10px',
          pl: '20px',
          mt: '15px',
        }}
      >
        <Box width="40px">
          <img src={data.img} alt={data.title} />
        </Box>
        <Typography
          variant="body1"
          color={COLORS.NEGRO}
          textAlign="center"
          fontFamily="TTNormsBold"
          fontSize="14pt"
          paddingX="2vw"
        >
          {data.title}
        </Typography>
      </Stack>
    );
  }

  const StyledButton = styled(Button)({
    height: '50px',
    width: '70vw',
    borderRadius: 0,
    backgroundColor: COLORS.VERDE_CLARO,
    color: COLORS.AZUL_MARINO,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: COLORS.VERDE_CLARO,
    },
    fontFamily: 'TTNormsBold',
    fontSize: '13pt',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <CustomBox component="section">
      <Typography
        color={COLORS.BEIGE}
        textAlign="center"
        fontFamily="TTNormsBold"
        fontSize="30pt"
        lineHeight="10vw"
        sx={{
          mb: '20px',
        }}
      >
        {t('productos.nuestro.text1')}
        <br />
        {t('productos.nuestro.text2')}
      </Typography>
      {card({
        img: IMG.saludSvg,
        title: t('productos.salud'),
        white: false,
      })}
      {card({
        img: IMG.saludSvg,
        title: t('productos.vida'),
        white: false,
      })}
      {card({
        img: IMG.saludSvg,
        title: t('productos.comple'),
        white: false,
      })}
      {card({
        img: IMG.saludSvg,
        title: t('productos.vision'),
        white: false,
      })}

      <StyledButton onClick={() => onClickWasa(isMobile)}>
        {t('productos.more')}
        <img
          src={IMG.arrowSvg}
          alt={'arrow'}
          color={COLORS.NEGRO}
          style={{
            width: '25px',
            marginLeft: '10px',
          }}
        />
      </StyledButton>
    </CustomBox>
  );
};

export default MobileProductosView;
