import React from 'react';
import { Box, styled, Typography, Stack, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const ObamacareView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.AZUL_MARINO,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  }));

  return (
    <CustomBox component="section">
      {isMobile && (
        <img
          src={IMG.obamacare2Png}
          alt="obamacareimg"
          style={{
            width: isMobile ? '100%' : '40vw',
            height: isMobile ? '230px' : '100vh',
            objectFit: 'cover',
          }}
        />
      )}
      <Box
        sx={{
          position: 'relative',
          backgroundImage: isMobile ? null : `url(${IMG.obamacarePng})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: '100vw',
          height: isMobile ? '50%' : '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          color: 'white',
          padding: isMobile ? null : '10vw 0',
        }}
      >
        <Stack
          direction="row"
          alignItems={isMobile ? 'center' : 'flex-end'}
          justifyContent={isMobile ? 'center' : 'flex-end'}
        >
          <Box
            sx={{
              width: isMobile ? '100%' : '38vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              justifyContent: 'center',
              pr: isMobile ? '0' : '6vw',
            }}
          >
            <img
              src={IMG.solSvg}
              alt="Wasa"
              color={COLORS.BEIGE}
              style={{
                width: isMobile ? '60px' : '7vw',
                marginBottom: isMobile ? '30px' : '3vw',
              }}
            />
            <Typography
              sx={{
                fontFamily: 'TTNormsBold',
                color: COLORS.VERDE_CLARO,
                fontSize: isMobile ? '16pt' : '3.5vw',
                lineHeight: isMobile ? '25px' : '3vw',
                mb: isMobile ? '30px' : '2vw',
              }}
            >
              {t('obamacare.title')}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'TTNormsRegular',
                color: COLORS.BLANCO,
                fontSize: isMobile ? '14pt' : '1.2vw',
                lineHeight: isMobile ? '25px' : '2vw',
                mb: isMobile ? '70px' : 0,
                textAlign: 'start',
                p: isMobile ? '0 30px' : 0,
              }}
            >
              {t('obamacare.text1')}
              <br />
              <br />
              {t('obamacare.text2')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </CustomBox>
  );
};

export default ObamacareView;
