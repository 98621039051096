import {
  Box,
  Button,
  Stack,
  Typography,
  keyframes,
  styled,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { COLORS } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import * as IMG from '../../../static/images';
import { MODULE_CONST } from '.';
import { Link } from 'react-scroll';
import * as Redux from 'react-redux';
import { IS_MENU_LANDING } from '../LandingReducer';
import { instagramHref } from '../contacto';

const slide = keyframes`
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const MenuMobileComponent = ({ lang, changeLang }) => {
  const dispatch = Redux.useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const StyledButton = styled(Button)({
    color: COLORS.BLANCO,
    textTransform: 'none',
    fontFamily: 'TTNormsRegular',
    fontSize: '20pt',
    padding: 0,
  });

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: '90',
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          zIndex: '100',
          backgroundColor: COLORS.VERDE_OSCURO,
          animation: `${slide} 0.5s ease`,
          p: '20px 50px',
        }}
      >
        <Link
          to={MODULE_CONST.contacto} // El id del elemento al que quieres desplazarte
          smooth={true}
          duration={500}
          onClick={() => dispatch({ type: IS_MENU_LANDING, payload: false })}
        >
          <Typography
            textAlign="start"
            onClick={() => {}}
            sx={{
              width: isMobile ? '80%' : '60%',
              fontFamily: 'TTNormsBold',
              color: COLORS.BLANCO,
              fontSize: isMobile ? '20pt' : '1.5vw',
              py: isMobile ? '10px' : '.7vw',
              borderBottom: `1.5px solid ${COLORS.BLANCO}`,
              cursor: 'pointer',
              mb: '20px',
            }}
          >
            {t('navbar.contacto')}
          </Typography>
        </Link>
        <Stack direction="row" spacing={2} sx={{ mb: '40px' }}>
          {/* <img
            src={IMG.faceSvg}
            alt="Facebook"
            color={COLORS.BEIGE}
            style={{ width: '40px' }}
            onClick={() => alert('Link de Facebook')}
          /> */}
          <a href={instagramHref} target="_blank" rel="noreferrer">
            <img
              src={IMG.instaSvg}
              alt="Instagram"
              color={COLORS.BEIGE}
              style={{ width: '40px' }}
            />
          </a>
        </Stack>
        <StyledButton
          onClick={() => {
            changeLang(lang === 'es' ? 'en' : 'es');
            dispatch({ type: IS_MENU_LANDING, payload: false });
          }}
        >
          {lang === 'es' ? t('langEn') : t('langEs')}
        </StyledButton>
      </Box>
    </Box>
  );
};

export default MenuMobileComponent;
