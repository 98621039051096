import { useSnackbar } from 'notistack';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = (message, options = {}) => {
    const defaultOptions = {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    };

    const actualOptions = {
      ...defaultOptions,
      ...options,
    };

    if (options.error) actualOptions.variant = 'error';
    if (options.warning) actualOptions.variant = 'warning';
    if (options.info) actualOptions.variant = 'info';
    if (options.black) actualOptions.variant = null;

    if (options.vertical) {
      actualOptions.anchorOrigin.vertical = options.vertical;
    }
    if (options.horizontal) {
      actualOptions.anchorOrigin.horizontal = options.horizontal;
    }

    const key = enqueueSnackbar(message, actualOptions);

    return {
      close: () => closeSnackbar(key),
    };
  };

  return {
    notify,
  };
};
